import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import TooltipComponent from "../../common/bootstrap-component/tooltip-component";
import FilledStarSvg from "../../common/icons/filled-star-svg";
import HollowStarSvg from "../../common/icons/hollow-star-svg";
import { initializeModal, hideModal } from "../../../util/util";

interface StarCommentModalProps {
    id: string;
    maxRating: number;
    rating: number;
    handleRate: (rating: number, comment: string) => void;
    messages: any;
}

const StarCommentModal: React.FC<StarCommentModalProps> = ({id, maxRating, rating, handleRate, messages}) => {
    const {t} = useTranslation();
    const [comment, setComment] = useState<string>("");
    const [isCommentError, setIsCommentError] = useState<boolean>(false);

    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(id, resetModalValues);
    
        return () => {
          // Call the initializeModal function on unmount/cleanup
          initializeModal(id, resetModalValues);
        };
    }, [id]);

    const resetModalValues = () => {
        setIsCommentError(false);
        setComment("");
    }

    
    // This function handles the validation of comment and calling of API.
    const handleRateBtn = () => {
        if(rating === 1 && comment.trim().length === 0)
        {
            setIsCommentError(true);
            return;
        }
        // This function is used to hide the modal.
        hideModal(id);
        handleRate(rating,comment);
    }

    // Handles onChange event of comment input field.
    const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setComment(value);
        setIsCommentError(value.trim().length === 0);
    }    

    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={id} tabIndex={-1} aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${id}Label`}>
                            {t('modals.comment_ratings.title')}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-3 mb-4">
                                {t('inputs.text.rated_stars.label')}
                            </div>
                            <div className="col-md-9 mb-4 disabled" style={{ cursor: 'not-allowed' }}>
                            {Array.from({ length: maxRating }, (_, i) => {
                                const message = messages && messages[i] ? messages[i] : { color: '', tooltip: '' };
                                // Determine the color for the star
                                const color = (i === 0 && rating > 1) ? 'var(--bs-primary)' : (i < rating ? message.color : '#007dbb');
                                return (
                                    i < rating ? (
                                        <FilledStarSvg key={i} color={color} isModalOpen={false} size={20} />
                                    ) : (
                                        <HollowStarSvg key={i} color={color} isModalOpen={false} size={20}/>
                                    )
                                );
                            })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <input 
                                type="text" 
                                className = {`form-control ${isCommentError ? 'is-invalid' : ''}`} 
                                placeholder={t('inputs.text.star_comment.placeholder')} 
                                value={comment}
                                onChange={handleComment}
                                />
                                <div className="invalid-feedback">
                                    {isCommentError && t('inputs.text.star_comment.validation_message.required')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <TooltipComponent title={t('buttons.close.tooltip')}>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            >
                                {t('buttons.close.label')}
                            </button>
                        </TooltipComponent>
                        <TooltipComponent title= {t('buttons.rate.tooltip')}>
                    <button type="button" className="btn btn-primary" onClick={handleRateBtn}>
                        {t('buttons.rate.label')}
                    </button>
                    </TooltipComponent>
                </div>
                </div>
            </div>
        </div>
    )
}

export default StarCommentModal;
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useAddFeedbackMutation } from '../../../api-integration/secure/secure';
import { getPageByURL, getTranslatedText, initializeModal } from '../../../util/util';
import { Modal } from "bootstrap";
import TooltipComponent from '../../common/bootstrap-component/tooltip-component';
import { fullPageLoader, updateAlertMessage } from '../../../api-integration/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import html2canvas from 'html2canvas'   
import usePageTranslation from '../../../hooks/usePageTranslation';
import EditTranslationIcon from '../../common/edit-translation-icon/EditTranslationIcon';

interface iModel {
    //FEEDBACKCONTENT: string;
    FEEDBACKRESOLUTION: string;
    FEEDBACKTILECODE: string;
    FEEDBACKSTATUS: string;
    FEEDBACKDETAILS: string;
    FEEDBACKPRIORITY:string;
    FEEDBACKTITLE: string; 
    FEEDBACKTILEPAGE: string;
    FEEDBACKBACKGROUND: string;
    FEEDBACKTYPE: string;
    FRQID: number;
    FEEDBACKVISIBILITY: string;
    screenshotPicPath: string;
}

interface FeedbackModalProps {
    id: string;
    feedbackTileCode: string;
    feedbackTilePage: string;
    feedback?: iModel;
    setReloadComponent?: (value:boolean) => void;
}

interface FeedbackDataInterface {
    title?: string;
    detail?: string;
    // content?: string;
    background?: string;
    type?: string;
    priority?: string;
    visibilty?: number;
}

const AddFeedbackModal:React.FC<FeedbackModalProps> = ({id,feedbackTileCode, feedbackTilePage, feedback, setReloadComponent}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const PageTranslation = usePageTranslation();
    const { user, TranslationEditFlag } = useSelector((state: any) => state.commonSlice);
    const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm<FeedbackDataInterface>();
    const [background, setBackground] = useState<string>("");
    const [detail, setDetail] = useState<string>("");
    const [priority, setPriority] = useState<string>('')
    const [type, setType] = useState("")
    const[localFeedback, setLocalFeedback] = useState<any>();
    const [localfeedbackVisibity, setLocalfeedbackVisibity] = useState<number>(1);
    const [capturedImage, setCapturedImage] = useState<string | undefined>(undefined);
    const [detailError, setDetailError] = useState(false); 
    const [contentError, setContentError] = useState(false);
    const [backgroundError, setBackgroundError] = useState(false);

    const [addFeedbackAPI, {data:feedbackData, isSuccess:isFeedbackSuccess, isError:isFeedbackError, error:feedbackError}] = 
    useAddFeedbackMutation();

    useEffect(() => {
        // Call the initializeModal function on mount/update
        initializeModal(id);
    
        return () => {
            // Call the initializeModal function on unmount/cleanup
            initializeModal(id);
        };
    }, [id]);
    
    const priorityOptions = [
        {value:"P1",label:"P1"},
        {value:"P2", label: "P2"},
        {value:"P3", label: "P3"},
        {value:"P4", label: "P4"}, 
    ];

    const typeOptions = [	
        {value:"", label: getTranslatedText("pleaseSelect.text", PageTranslation)},
        {value:"bug", label: "Bug"},
        {value:"feature", label: "Feature Request"}, 
        {value:"feedback", label: "Change Request"},
        {value:"suggestion", label: "General Comment"},
    ];

    useEffect(() => {
        if(feedback !== null)
        {
            setLocalFeedback(feedback)
        }
        else
        {
            setLocalFeedback(null)
        }
    },[feedback])

    const setFeedbackValues = (feedback: any) => {
        const findSelectedValue = (options: any[], value: any, defaultValue: any) =>
            options.find(option => option.value === value) ? value : defaultValue;
        const selectedTypeValue = findSelectedValue(typeOptions, localFeedback?.FEEDBACKTYPE, "");
        const selectedPriorityValue = findSelectedValue(priorityOptions, localFeedback?.FEEDBACKPRIORITY, "P4");
        setValue('title', feedback?.FEEDBACKTITLE || '');
        setValue('type', selectedTypeValue);
        setValue('priority', selectedPriorityValue);
        setValue('visibilty', feedback?.FEEDBACKVISIBILITY=='I' ? 0 : 1);
        setLocalfeedbackVisibity(feedback?.FEEDBACKVISIBILITY=='I' ? 0 : 1);
        setDetail(feedback?.FEEDBACKDETAILS || '');
        //setContent(feedback?.FEEDBACKCONTENT || '');
        setBackground(feedback?.FEEDBACKBACKGROUND || '');
        setPriority(feedback?.FEEDBACKPRIORITY || '');
        setType(selectedTypeValue === '' ? "Please select" : selectedTypeValue);
        setPriority(selectedPriorityValue === 'P4' ? 'P4' : selectedPriorityValue);
    }

    useEffect(() => {
        setFeedbackValues(localFeedback || {});
    }, [feedback, localFeedback, setValue]);

    useEffect(() => {
        if(isFeedbackSuccess || isFeedbackError || feedbackError){
            dispatch(fullPageLoader(false));
        }
        if(feedbackData){
            if(feedbackData?.success === true){
                dispatch(updateAlertMessage({ status: 'success', message: feedbackData?.message }));
                //setReloadComponent && setReloadComponent(true);
                const page = getPageByURL(location.pathname);
                if(page === 'ManageUserFeedback' || page === 'myFeedback'){
                    //dispatch(fullPageLoader(true));
                    //window.location.reload();
                    setTimeout(() => {
                        window.location.reload();
                    },user?.usrMessageAlertDuration || Number(process.env.REACT_APP_ALERT_TIMEOUT));
                }
            }
            else if (feedbackData?.success == false) {
                dispatch(updateAlertMessage({ status: 'error', message: feedbackData?.message }));
                //setReloadComponent && setReloadComponent(true);
            }
            else{
                dispatch(updateAlertMessage({ status: 'error', message: feedbackData?.message }));
            }
        }
    }, [isFeedbackSuccess, isFeedbackError, feedbackError, feedbackData]);

    const handleDetailChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDetail(event.target.value);
        if(event.target.value?.length > 2500) 
            setDetailError(true);
        else 
            setDetailError(false);
    };

    // const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    //     setContent(event.target.value);
    //     if(event.target.value?.length > 2500)
    //         setContentError(true);
    //     else
    //         setContentError(false);
    // };

    const handleBackgroundChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setBackground(event.target.value);
        if(event.target.value?.length > 2500)
            setBackgroundError(true);
        else
            setBackgroundError(false);
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        clearErrors('type');
        setType(event.target.value)
    }

    const handlePriorityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPriority(event.target.value)
    }

    const onSubmit = (data: any) => {
        if(detailError || contentError || backgroundError)
            return;
        // Include rich text editor values in form data
        //data.content = content;
        data.background = background;        
        data.detail = detail;

        // Close modal
        const myModalEl = document.getElementById(id);
        if (myModalEl) {
            const modal = Modal.getInstance(myModalEl);
            if (modal) {
                modal?.hide();
                resetModalValues();
            }
        }
        dispatch(fullPageLoader(true));
        addFeedbackAPI({
            FEEDBACKTITLE: data.title,
            //FEEDBACKCONTENT: data.content,
            FEEDBACKBACKGROUND: data.background,
            FEEDBACKDETAILS: data.detail,
            FEEDBACKTYPE: data.type,
            FEEDBACKPRIORITY: data.priority,
            FEEDBACKTILECODE: feedbackTileCode,
            //FEEDBACKTILEPAGE: getPageByURL(location.pathname),
            feedbackScreenShot:capturedImage,
            FEEDBACKVISIBILITY: localfeedbackVisibity == 1 ? 'P' : 'I',
            feedbackTilePage: feedbackTilePage
        });
    };

    const resetModalValues = () => {
        clearErrors(["title","type","priority"]);
        //setContent('');
        setDetail('');
        setBackground('');
        setType('');
        setPriority('P4');
        setCapturedImage(undefined);
        setValue("title", "");
        setValue("type", "");
        setValue("priority", "P4");
        setValue("visibilty", 0);
        setContentError(false);
        setBackgroundError(false);
        setDetailError(false);
    }    
    
    const handleCapturedImage = () => {
        let myModalEl = document.getElementById(id);
        dispatch(fullPageLoader(true));
        if (myModalEl) {
            var modal = Modal.getInstance(myModalEl);
            modal?.hide();
            setTimeout(() => {
                dispatch(fullPageLoader(false));
            },1000);
            setTimeout(() => {
                modal?.show();
            }, 2000);
        }
        setTimeout(() => {
            html2canvas(document.getElementById("root") as HTMLElement).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                setCapturedImage(imgData)
            });
        }, 1000);        
    }

    const handleChangeVisibilty = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalfeedbackVisibity(Number(event.target.value));
    }
    return (
        <div className="modal fade modal-draggable" data-bs-backdrop="false" id={id} tabIndex={-1} aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="modal-header">
                            <h5 className="modal-title" id={`${id}Label`}>
                                {getTranslatedText('feedback.modal.title',PageTranslation)}
                                {
                                    TranslationEditFlag && (
                                        <EditTranslationIcon translationKey="feedback.modal.title" translationType="text" />
                                    )
                                }
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => resetModalValues()}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3 text-center mx-auto">
                                        <input type="range" 
                                            className="form-range bc-range" 
                                            min="0" max="1" 
                                            step={1} 
                                            {...register('visibilty')}
                                            value={localfeedbackVisibity}
                                            onChange={handleChangeVisibilty}
                                        />
                                        <div className="d-flex justify-content-between">
                                            <TooltipComponent title={getTranslatedText('feedbackVisibilitySupportTeam.tooltip',PageTranslation)} >
                                                <small>
                                                    {getTranslatedText('feedbackVisibilitySupportTeam.text',PageTranslation)}
                                                </small>
                                            </TooltipComponent>
                                            {
                                                TranslationEditFlag && (
                                                    <>
                                                        <EditTranslationIcon translationKey="feedbackVisibilitySupportTeam.text" translationType="text" />
                                                        <EditTranslationIcon translationKey="feedbackVisibilitySupportTeam.tooltip" translationType="tooltip" />
                                                    </>
                                                )
                                            }
                                                <TooltipComponent title={getTranslatedText('feedbackVisibilityPublic.tooltip',PageTranslation)} >
                                                    <small>
                                                        {getTranslatedText('feedbackVisibilityPublic.text',PageTranslation)}
                                                    </small>
                                                </TooltipComponent>
                                                {
                                                    TranslationEditFlag && (
                                                        <>
                                                            <EditTranslationIcon translationKey="feedbackVisibilityPublic.text" translationType="text" />
                                                            <EditTranslationIcon translationKey="feedbackVisibilityPublic.tooltip" translationType="tooltip" />
                                                        </>
                                                    )
                                                }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className='col'>
                                    <label htmlFor={id+'title'} className="form-label">
                                        <TooltipComponent title={getTranslatedText('feedbackTitle.tooltip',PageTranslation)}>
                                            {getTranslatedText('title.text',PageTranslation)}<span className='text-danger'>*</span>
                                        </TooltipComponent>
                                    {
                                        TranslationEditFlag && (
                                        <>
                                            <EditTranslationIcon translationKey="title.text" translationType="text" />
                                            <EditTranslationIcon translationKey="feedbackTitle.tooltip" translationType="tooltip" />
                                            <EditTranslationIcon translationKey="feedbackTitle.placeholder" translationType="placeholder" />
                                        </>
                                        )
                                    }
                                    </label>
                                    <input 
                                        type="text"
                                        className={`form-control ${errors?.title ? 'is-invalid' : ''}`}
                                        id={id+'title'}
                                        placeholder={getTranslatedText('feedbackTitle.placeholder',PageTranslation)}
                                        {...register('title', { 
                                            required: true, 
                                            maxLength:100,
                                            validate: value => value !== undefined && value.trim().length > 0
                                        })} 
                                    />
                                    <div className="invalid-feedback">
                                        {errors.title && (errors.title.type === 'required' || errors.title.type === 'validate') && (
                                            <div>
                                                {getTranslatedText('title.required.message',PageTranslation)}
                                                {TranslationEditFlag &&  <EditTranslationIcon translationKey="title.required.message" translationType="text" />}
                                            </div>
                                        )}
                                        {errors.title && errors.title.type === 'maxLength' && (
                                            <div>
                                                {getTranslatedText('maxlength100.message',PageTranslation)}
                                                {TranslationEditFlag && <EditTranslationIcon translationKey="maxlength100.message" translationType="text" />}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <label htmlFor={id+'detail'} className="form-label">
                                        <TooltipComponent title={getTranslatedText('feedbackDetail.tooltip',PageTranslation)}>
                                                {getTranslatedText('detail.text',PageTranslation)}
                                        </TooltipComponent>
                                        {
                                            TranslationEditFlag && (
                                                <>
                                                    <EditTranslationIcon translationKey="detail.text" translationType="text" />
                                                    <EditTranslationIcon translationKey="feedbackDetail.tooltip" translationType="tooltip" />
                                                    <EditTranslationIcon translationKey="feedbackDetail.placeholder" translationType="placeholder" />
                                                </>
                                            )
                                        }
                                    </label>
                                    <textarea 
                                        id={id+'detail'}
                                        className='form-control'
                                        placeholder={getTranslatedText('feedbackDetail.placeholder',PageTranslation)}
                                        rows={2}
                                        value={detail}
                                        onChange={handleDetailChange}
                                    >
                                    </textarea>
                                    {detailError === true &&    (
                                        <div>
                                            <small className='error text-danger'>{getTranslatedText('maxlength2500.message',PageTranslation)}</small>
                                            {TranslationEditFlag && <EditTranslationIcon translationKey="maxlength2500.message" translationType="text" />}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* <div className="row mb-3">
                                <div className="col">
                                    <label htmlFor="content" className="form-label">{t('inputs.text.feedback_content.label')}</label>
                                    <textarea  id="content" 
                                    className='form-control' 
                                    placeholder={t('inputs.text.feedback_content.placeholder')} 
                                    rows={2} 
                                    value={content} 
                                    onChange={handleContentChange}>
                                    </textarea>
                                    {contentError === true && <small className='error text-danger'>{t('inputs.text.feedback_content.maxlength_validation')}</small>}
                                </div>
                            </div> */}
                            <div className="row mb-3">
                                <div className="col">
                                    <label htmlFor={id+'background'} className="form-label">
                                        <TooltipComponent title={getTranslatedText('feedbackExpectation.tooltip',PageTranslation)}>
                                                {getTranslatedText('expectation.text',PageTranslation)}
                                        </TooltipComponent>
                                        { 
                                            TranslationEditFlag && (
                                            <>
                                                <EditTranslationIcon translationKey="expectation.text" translationType="text" />
                                                <EditTranslationIcon translationKey="feedbackExpectation.tooltip" translationType="tooltip" />
                                                <EditTranslationIcon translationKey="feedbackExpectation.placeholder" translationType="placeholder" />
                                            </>
                                            )
                                        }
                                        </label>
                                    <textarea 
                                        id={id+'background'}
                                        className='form-control' 
                                        placeholder={getTranslatedText('feedbackExpectation.placeholder',PageTranslation)} 
                                        rows={2} 
                                        value={background} 
                                        onChange={handleBackgroundChange}>
                                    </textarea>
                                    {backgroundError && (
                                        <div>
                                            <small className='error text-danger'>{getTranslatedText('maxlength2500.message',PageTranslation)}</small>
                                            {TranslationEditFlag && <EditTranslationIcon translationKey="maxlength2500.message" translationType="text" />}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-6">
                                    <label htmlFor={id+'type'} className="form-label">
                                        <TooltipComponent title={getTranslatedText('feedbackType.tooltip',PageTranslation)}>
                                                {getTranslatedText('feedbackType.text',PageTranslation)}<span className='text-danger'>*</span>        
                                        </TooltipComponent>
                                        {
                                            TranslationEditFlag && (
                                            <>
                                                <EditTranslationIcon translationKey="feedbackType.text" translationType="text" />
                                                <EditTranslationIcon translationKey="feedbackType.tooltip" translationType="tooltip" />
                                            </>
                                            )
                                        }
                                    </label>
                                    <select
                                        id={id+'type'}
                                        className={`form-select ${errors?.type ? 'is-invalid' : ''}`}
                                        {...register('type',{required:true})}
                                        value={type}
                                        onChange={handleTypeChange}
                                    >
                                        {typeOptions?.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">
                                        {errors.type && errors.type.type === 'required' && (
                                            <div>
                                                {getTranslatedText('feedbackType.required.message',PageTranslation)}
                                                {TranslationEditFlag && <EditTranslationIcon translationKey="feedbackType.required.message" translationType="text" />}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <label htmlFor={id+'priority'} className="form-label">
                                        <TooltipComponent title={getTranslatedText('feedbackPriority.tooltip',PageTranslation)}>
                                                {getTranslatedText('feedbackPriority.text',PageTranslation)}<span className='text-danger'>*</span>
                                        </TooltipComponent>
                                        {
                                            TranslationEditFlag && (
                                                <>
                                                    <EditTranslationIcon translationKey="feedbackPriority.text" translationType="text" />
                                                    <EditTranslationIcon translationKey="feedbackPriority.tooltip" translationType="tooltip" />
                                                </>
                                            )
                                        }
                                    </label>
                                    <select
                                        id={id+'priority'}
                                        className="form-select"
                                        {...register('priority', { required: true })}
                                        value={priority}
                                        onChange={handlePriorityChange}
                                    >
                                        {priorityOptions?.map(option => (
                                            <option key={option.value} value= {option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">
                                        {errors.priority && errors.priority.type === 'required' && (
                                            <div>
                                                {getTranslatedText('feedbackPriority.required.message',PageTranslation)}
                                                {TranslationEditFlag && <EditTranslationIcon translationKey="feedbackPriority.required.message" translationType="text" />}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {capturedImage && (
                                <div className='row'>
                                    <label htmlFor="Capture">{getTranslatedText('captureScreen.text',PageTranslation)}</label>
                                    {
                                        TranslationEditFlag && (
                                            <EditTranslationIcon translationKey="captureScreen.text" translationType="text" />
                                        )
                                    }
                                    {capturedImage && <img src={capturedImage} alt='image captured'/>}
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <TooltipComponent title={getTranslatedText('close.tooltip',PageTranslation)}>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" 
                                onClick={() => resetModalValues()}>
                                    {getTranslatedText('close.text',PageTranslation)}
                                </button>
                            </TooltipComponent>
                            {TranslationEditFlag && (
                                <>
                                    <EditTranslationIcon translationKey="close.text" translationType="text" />
                                    <EditTranslationIcon translationKey="close.tooltip" translationType="tooltip" />
                                </>
                            )}
                            <TooltipComponent title={getTranslatedText('captureScreen.tooltip',PageTranslation)}>
                                <button type="button" className="btn btn-primary" onClick={handleCapturedImage}>
                                    {getTranslatedText('captureScreen.text',PageTranslation)}
                                </button>
                            </TooltipComponent>
                            {
                                TranslationEditFlag && (
                                    <>
                                        <EditTranslationIcon translationKey="captureScreen.text" translationType="text" />
                                        <EditTranslationIcon translationKey="captureScreen.tooltip" translationType="tooltip" />
                                    </>
                                )
                            }
                            <TooltipComponent title={getTranslatedText('submit.tooltip',PageTranslation)}>
                                <button type="submit" className="btn btn-primary">{getTranslatedText('submit.text',PageTranslation)}</button>
                            </TooltipComponent>
                            {
                                TranslationEditFlag && (
                                    <>
                                        <EditTranslationIcon translationKey="submit.text" translationType="text" />
                                        <EditTranslationIcon translationKey="submit.tooltip" translationType="tooltip" />
                                    </>
                                )
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddFeedbackModal;

import { useTranslation } from "react-i18next";
import Card from "../../../common/card/card";
import { set, useForm } from "react-hook-form";
import { useCopyPromptsMutation, usePromptStatisticsMutation } from "../../../../api-integration/secure/prompts";
import { useEffect, useState } from "react";
import History from '../../../../assets/icons/history.svg';
import { fullPageLoader, updateAlertMessage, updateUser } from "../../../../api-integration/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Flag from '../../../../assets/icons/bluePrompt.svg';
import Delete from '../../../../assets/icons/delete.svg';
import Embed from '../../../../assets/icons/code-outline.svg';
import TooltipComponent from "../../../common/bootstrap-component/tooltip-component";
import HelpModal from "../../../common/help-modal/help-modal";
import { use } from "i18next";
import WelcomeNonLoginCard from "../../../common/modal/welcome-non-login-user";
import ConfirmationPopup from "../../../common/modal/confirmation-popup";
import { useGetUserProfileMutation, useRemovePromptFromLibMutation } from "../../../../api-integration/secure/secure";



interface askGptFormInterface {
  userPrompt: string,
  DUALPROMPTREFERENCEDATA: string,
  authorView: boolean,
  autoSave: boolean,
  ManualEffort: number,
  dontShowExeAlert: boolean,
}

interface Props {
  prompt: any,
  TriggerAskGpt: (data: any) => void,
  askGptResponse: any,
  loading: boolean,
  isLibraryTypeChanged: boolean,
  setReloadPromptList: (reloadPromptList: boolean) => void;
  setWiderLayout: (widerLayout: boolean) => void;
  pageType?: string;
}

const PromptExecution = (props: Props) => {
  const promptId = props?.prompt?.GPTBLUEPROMPTID;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [userEmail, setUserEmail] = useState('');
  const [userPromptCCount, setUserPromptCCount] = useState(props?.prompt?.DEFAULTACTION ? 2500-props?.prompt?.DEFAULTACTION.length : 2500);
  const [referenceDataCCount, setReferenceDataCCount] = useState(props?.prompt?.DUALPROMPTREFERENCEDATA ? 2500-props?.prompt?.DUALPROMPTREFERENCEDATA.length : 2500);
  const [gain, setGain] = useState(2);
  const [showBluePrompt, setShowBluePrompt] = useState(false);
  const [localAuthorView, setLocalAuthorView] = useState(false);
  const [averageCCUsed, setAverageCCUsed] = useState(props?.prompt?.AverageCC || 0);  
  const [editAccess, setEditAcccess] = useState(false);
  const [formData, setFormData] = useState<askGptFormInterface>();
  const [helpTitle, setHelpTitle] = useState('');
  const [helpContent, setHelpContent] = useState('');
  const { user } = useSelector((state: any) => state.commonSlice);
  const [dontShowExeAlert, setDontShowExeAlert] = useState<boolean>(true);
  const [isHidden, setIsHidden] = useState(props?.isLibraryTypeChanged);
  const [isAllowEmbedScript, setIsAllowEmbedScript] = useState(props?.prompt?.allowEmbedScript == 1 ? true : false);
  const { register, handleSubmit, trigger, formState: { errors }, setValue, getValues, watch, reset } = useForm<askGptFormInterface>();
  const userPromptHasValue = watch('userPrompt');
  const referenceDataHasValue = watch('DUALPROMPTREFERENCEDATA');
  const [layoutMode, setLayoutMode] = useState("0");
  const [bottomText, setBottomText] = useState('');
  
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
  const [getPromptsStatisticsAPI, { data: promptStats, isLoading, isSuccess: promptStatsIsSuccess, isError: promptStatsIsError, error }] =
    usePromptStatisticsMutation(); 
  
  const [copyPromptsAPI, { data: copyPromptsData, isLoading: isCopyPromptLoading, isSuccess: isCopyPromptSuccess, isError: isCopyPromptError, error: copyPromptError }] =
    useCopyPromptsMutation();
  
  const [getUserDetailsAPI, { data: userDetailsData, isLoading: isUserDetailsLoading , isSuccess: isUserDetailsSuccess, isError: isUserDetailsError, error: userDetailsError}] =
    useGetUserProfileMutation();
  const [removePromptFromLibAPI, { data: removePromptFromLibData, isLoading: isRemovePromptFromLibLoading, isSuccess: isRemovePromptFromLibSuccess, isError: isRemovePromptFromLibError, error: removePromptFromLibError }] =
    useRemovePromptFromLibMutation();

  const additionalFooterIcons = [
    {
      visibility: params.id && props?.prompt ? true : false,
      src: History,
      tooltip: "promptExecutionHistory.card.tooltip",
      onClick: () => {
        navigate(`/app/prompts/result/${props?.prompt?.GPTBLUEPROMPTID}`);
      },
    }
    //,
    // {
    //   src: History,
    //   tooltip: 'Tooltip for icon 2',
    //   onClick: () => {
    //     // Handle click event for icon 2
    //   },
    // },
    // Add more icons as needed
  ];

  //const showWelcomeCard = !(isLoggedIn || params.id == '' || !params.id || params.id == undefined || params.id == null); 
    
  const showWelcomeCard = !isLoggedIn && props.pageType != 'embed';

  const onSubmit = (data: askGptFormInterface) => {
    if (dontShowExeAlert) {
        props.TriggerAskGpt({
        ...data,
        authorView: data?.authorView ? 1 : 0,
        autoSave: data?.autoSave ? 1 : 0,
        promptExeAlert: 0  //value of dontShowExeAlert checkbox in modal is checked OR value from DB is set as True then pass 0 to prompt i.e. dont show execution alert in future
      })
    }
    else{
      setFormData(data);
    }
  }
  const handleConfirmPromptExecution = () => {
    if (formData) {
      props.TriggerAskGpt({
        ...formData,
        authorView: formData?.authorView ? 1 : 0,
        autoSave: formData?.autoSave ? 1 : 0,
        promptExeAlert: dontShowExeAlert ? 0 : 1 //if checkbox is checked i.e. dont show execution alert in future so pass 0 else pass 1
      })
      //setAverageCCUsed(props.averageCCNumber);
    }
  };
  
  const handleConfirmDeleteDownloadedPrompt = () => {
    dispatch(fullPageLoader(true));
    removePromptFromLibAPI({ "promptId": props?.prompt?.GPTBLUEPROMPTID });
  };

  const handleChange = (event: any) => {
    setUserEmail(event.target.value);
  };
  useEffect(() => {
    if (isCopyPromptSuccess) {
        dispatch(fullPageLoader(false));
        dispatch(updateAlertMessage({ status: 'success', message: copyPromptsData?.message }));
        navigate(`/app/prompts/edit/${copyPromptsData?.promptId}`)
    }
    if (isCopyPromptError) {
        dispatch(fullPageLoader(false));
        dispatch(updateAlertMessage({ status: 'error', message: copyPromptsData?.message }));
    }
  }, [isCopyPromptSuccess, isCopyPromptError]);

  useEffect(() => {
    if (isRemovePromptFromLibSuccess) {
      dispatch(fullPageLoader(false));
      if(removePromptFromLibData.success){
        dispatch(updateAlertMessage({ status: 'success', message: removePromptFromLibData?.message }));
        setBottomText('');
        navigate('/app/io');
        props?.setReloadPromptList(true);
      }else{
        dispatch(updateAlertMessage({ status: 'error', message: removePromptFromLibData?.message }));
      }
    }
    if (isRemovePromptFromLibError) {
      dispatch(fullPageLoader(false));
      dispatch(updateAlertMessage({ status: 'error', message: removePromptFromLibData?.message }));
    }
    if(removePromptFromLibError){
      dispatch(fullPageLoader(false));
      dispatch(updateAlertMessage({ status: 'error', message: t('message.common_error') }));
    }
  }, [isRemovePromptFromLibSuccess, isRemovePromptFromLibError, removePromptFromLibError]);

  useEffect(() => {
    setIsHidden(props?.isLibraryTypeChanged);
  }, [props?.isLibraryTypeChanged]);

  useEffect(() => {
    setIsAllowEmbedScript(props?.prompt?.allowEmbedScript == 1 ? true : false);
  }, [props?.prompt?.allowEmbedScript]);

  useEffect(() => {
    dispatch(fullPageLoader(true));
    getUserDetailsAPI({});
  }, []);
  useEffect(() => {
    if(isUserDetailsSuccess || isUserDetailsError || userDetailsError){
      dispatch(fullPageLoader(false));
    }
  }, [isUserDetailsSuccess, isUserDetailsError, userDetailsError]);

  useEffect(() => {
    if (promptId !== undefined) {
      reset();
      dispatch(fullPageLoader(true));
      getPromptsStatisticsAPI({ 
        objId: promptId, 
        statsType: "prompt"
      });
      setValue('userPrompt', props?.prompt?.DEFAULTACTION);
      setUserPromptCCount(2500 - props?.prompt?.DEFAULTACTION.length);
      setValue('DUALPROMPTREFERENCEDATA', props?.prompt?.DUALPROMPTREFERENCEDATA);
      setReferenceDataCCount(2500 - props?.prompt?.DUALPROMPTREFERENCEDATA.length);
      setLocalAuthorView(false);
      //setValue('authorView', props?.prompt?.AUTHORVIEW == 1 ? true : false);
      if(user?.promptExeAlert == 1){
        setDontShowExeAlert(false);
      }else{
        setDontShowExeAlert(true);
      }
      let userHasAccess = false;
      props?.prompt?.promptTeam?.map((promptUser: any) => {
        if (user?.userId == promptUser?.USERID) {
          userHasAccess = true
        }
      })
      if (user?.id == props?.prompt?.OWNERUSERID || userHasAccess) {
        setEditAcccess(true);
      } else {
        setEditAcccess(false);
      }
    }
  }, [promptId]);


  useEffect(() => {
    if (promptStatsIsSuccess || promptStatsIsError) {
      dispatch(fullPageLoader(false));
    }
    if (promptStatsIsError) {
      dispatch(updateAlertMessage({ status: 'error', message: t('prompt.analytics_fetch_error') }));
    }
    if (promptStatsIsSuccess) {
      if (promptStats?.success == false && promptStats?.statusCode != 401) {
        if (promptStats?.status == 'FETCH_ERROR' || promptStats?.status == 'PARSING_ERROR') {
          dispatch(updateAlertMessage({ status: 'error', message: t('message.common_error') }));
        } else {
          dispatch(updateAlertMessage({ status: 'error', message: promptStats?.message }));
        }
      }
    }
  }, [promptStatsIsSuccess, promptStatsIsError]);

  const selectLayoutMode = (e: any) => {
    setLayoutMode(e.target.value);
    if (e.target.value == 0) {
      props.setWiderLayout(true);
    } else {
      props.setWiderLayout(false);
    }
  }
  
  const copyPrompt = () => {
    dispatch(fullPageLoader(true));
    copyPromptsAPI({ "GPTBluePromptId": props?.prompt?.GPTBLUEPROMPTID });
  }

  useEffect(() => {
    if(props?.prompt?.AverageCC) {
      setAverageCCUsed(props?.prompt?.AverageCC);
    }
    else {
      setAverageCCUsed(0);
    }
  }, [props?.prompt]);

  useEffect(() => {
    if(props?.askGptResponse?.averageCC) {
      setAverageCCUsed(props?.askGptResponse?.averageCC);
    }
  }, [props?.askGptResponse]);

  useEffect(() => {
    if(props?.prompt?.BLUEPROMPT == 1) {
      setShowBluePrompt(true);
    }else {
      setShowBluePrompt(false);
    }
  }, [props?.prompt?.BLUEPROMPT]);

  useEffect(() => {
    if(props?.prompt?.OWNERFIRSTNAME && props?.prompt?.OWNERLASTNAME && props?.prompt?.OWNERCOMPANY){
      setBottomText(`${t('links.author.label')}: ${props?.prompt?.OWNERFIRSTNAME} ${props?.prompt?.OWNERLASTNAME}, ${props?.prompt?.OWNERCOMPANY}`);
    }
  }, [props?.prompt?.OWNERFIRSTNAME, props?.prompt?.OWNERLASTNAME, props?.prompt?.OWNERCOMPANY]);

  // const bottomText = props?.prompt?.OWNERFIRSTNAME
  //   ? `${t('links.author.label')}: ${props.prompt.OWNERFIRSTNAME} ${props.prompt.OWNERLASTNAME}, ${props.prompt.OWNERCOMPANY}`
  //   : '';
  
  
  // const handleAbortPromptExecution = () => {
  //   //console.log('User cancelled');
  // };
  const handleChangeAuthorView = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalAuthorView(event.target.checked);
    //setValue('authorView', event.target.checked);
  };

  let generateResponseBtntooltip: string;

  if(props?.prompt?.DUALPROMPT){
    //dual prompt
    if (userPromptHasValue && referenceDataHasValue) {
      //value in both fields
      generateResponseBtntooltip = t('buttons.ask_GPTBlue.tooltip');
    }
    else if (!userPromptHasValue && !referenceDataHasValue) {
      //no value in both fields
      generateResponseBtntooltip = t('prompt.input_reference_data_required');
    } 
    else if (!userPromptHasValue) {
      //no value in user prompt (input data [label show on screen] in case of dual prompt)
      generateResponseBtntooltip = t('prompt.input_data_required');
    }
    else{
      //no value in reference data
      generateResponseBtntooltip = t('prompt.reference_data_required');
    }

  }
  else if (props?.prompt?.SURVEYPROMPT){
    //survey prompt
    if (userPromptHasValue) {
      //value in user prompt
      generateResponseBtntooltip = t('buttons.review_response.tooltip');
    } else {
      //no value in user prompt
      generateResponseBtntooltip = t('prompt.askgpt_required');
    }
  }
  else if (userPromptHasValue) {
    //value in user prompt
    generateResponseBtntooltip = t('buttons.ask_GPTBlue.tooltip');
  }
  else {
    //no value in user prompt
    generateResponseBtntooltip = t('prompt.askgpt_required');
  }

  const copyEmbedCode = async () => {
    //const iframeCode = `<iframe src="${window.location.origin}/app/embed/prompt/${props?.prompt?.URLCODE}" sandbox="allow-forms allow-scripts allow-same-origin allow-presentation" width="100%" height="100%" frameborder="0"></iframe>`;
    const iframeCode = `<iframe src="${window.location.origin}/app/askgpt/${props?.prompt?.URLCODE}" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen width="100%" height="100%" frameborder="0"></iframe>`;
    // <iframe width="560" height="315" src="https://gptbluedev.bluecallom.ai/app/embed/prompt/gptblueA4BA54BD-0284-3ADF-2BB35A6BAED70261" title="GPTBlue Prompt" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    try {
      await navigator.clipboard.writeText(iframeCode);
      dispatch(updateAlertMessage({ status: 'success', message: t('message.embed_code_copied') }));
    } catch (err) {
      //console.error('Failed to copy text: ', err);
    }
  }

  return (
    <>
      <Card id="io_promptExecution" titleType={1} cardHeightClass="h-100" title={t('prompt.prompt_exection_title')} help={true} 
        Feedback={props.pageType == 'embed' ? false : true} 
        like={params.id && props.pageType != 'embed' ? true : false} 
        share={params.id && props.pageType != 'embed' ? true : false} 
        settings={isHidden || props.pageType == 'embed' ? false : editAccess} 
        settingsClicked={() => navigate(`/app/prompts/edit/${promptId}`)} 
        logo={true} 
        bottomTextFlag={isHidden || props.pageType == 'embed' ? false : true} 
        bottomText={bottomText} 
        helpTitle={t('prompt.io_ai_prompt_execution.help.title')} 
        helpContent={t('prompt.io_ai_prompt_execution.help.content')}
        {...(isHidden || props.pageType == 'embed' ? {} : { additionalFooterIcons: additionalFooterIcons })} 
        //additionalFooterIcons={additionalFooterIcons}
      >
          
        {(!params.id || !props?.prompt || props?.isLibraryTypeChanged) &&
          <div>
            <div className="row">
              <div className="col-12">
                <div className="my-3">
                  {isLoggedIn && userDetailsData?.user?.betaUser 
                  ?
                    <h4>{t('prompt.prompt_select')}</h4>
                  :
                    <h4>{t('prompt.prompt_select_nonloggedIn')}</h4>
                  }
                </div>
              </div>
            </div>
          </div>
        }
        <div className={`${params.id ? '' : 'd-none'} ${props?.prompt ? '' : 'd-none'} ${isHidden ? 'd-none': ''}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className={props.pageType == 'embed' ? "col-md-12" : "col-md-7"}>
                <h6 className="cursor-pointer"
                  data-bs-toggle="modal" 
                  data-bs-target='#prompt_name_help'
                  onClick={() => {
                    setHelpTitle(props?.prompt?.PROMPTNAME);
                    let content = props?.prompt?.PROMPTDESCRIPTION;
                    if (props?.prompt?.OPENSOURCE == 1 && props?.prompt?.BLUEPROMPT == 1) {
                        content += t('message.prompt_open_source') + t('message.prompt_blue_prompt');
                    } else if (props?.prompt?.OPENSOURCE == 1) {
                        content += t('message.prompt_open_source');
                    } else if (props?.prompt?.BLUEPROMPT == 1) {
                        content += t('message.prompt_blue_prompt');
                    }
                    setHelpContent(content);
                  }}
                >
                  {showBluePrompt && <img src={Flag} className='h-1-5 cursor-pointer'/>}
                  <TooltipComponent title={t('text.prompt_name.tooltip')}>
                    {props?.prompt?.PROMPTNAME}
                  </TooltipComponent>
                </h6>
                {props?.prompt?.INSTRUCTIONTOUSER &&
                  <div className="my-2 p-1 overflow-auto maxH-5 text-break scrollbar">
                    {props?.prompt?.INSTRUCTIONTOUSER}
                  </div>
                }
                {props?.prompt?.EXPIREON &&
                  <div className="my-2">
                    <span className="fw-bold">{t('inputs.text.expire_on_io.label')}</span>&nbsp;
                    {props?.prompt?.EXPIREON ? new Date(props?.prompt?.EXPIREON).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }) + ' | ' + new Date(props?.prompt?.EXPIREON).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) : '---'}
                    &nbsp;CET
                  </div>
                }
                {props?.prompt?.DUALPROMPT ==1 && 
                  <div className="mb-3">
                    <label htmlFor="referenceData" className="fw-bold">
                      {t('prompt.reference_data')}
                    </label>
                    <textarea className="form-control" 
                        id="referenceData" 
                        {...register('DUALPROMPTREFERENCEDATA', { required: true })} 
                        rows={8} maxLength={2500}
                        onChange={e => {
                          setReferenceDataCCount(2500 -e.target.value.length);
                          setValue('DUALPROMPTREFERENCEDATA', e.target.value);
                        }}
                    />
                    <div className="invalid-feedback">
                      {errors.DUALPROMPTREFERENCEDATA && errors.DUALPROMPTREFERENCEDATA.type == 'required' && t('prompt.reference_data_required')}
                    </div>
                    <div>
                      <small className="text-muted">
                        {referenceDataCCount}{t('message.characters_remaining')}
                      </small>
                    </div>
                </div>
                }
                <div className="mb-3 ">
                  {props?.prompt?.DUALPROMPT ==1 &&
                    <label htmlFor="userPrompt" className="fw-bold">
                      {t('prompt.input_data')}
                    </label>
                  }
                  <textarea 
                    className={`form-control scrollbar ${errors?.userPrompt ? 'is-invalid' : ''}`} 
                    id="userPrompt" 
                    {...register('userPrompt', { required: true })} 
                    rows={3} 
                    maxLength={2500}
                    onChange={e => {
                      setUserPromptCCount(2500 -e.target.value.length);
                      setValue('userPrompt', e.target.value);
                    }}
                  />
                  <div className="invalid-feedback">
                    {errors.userPrompt && errors.userPrompt.type == 'required' && t('prompt.askgpt_required')}
                  </div>
                  <small className="form-text text-muted">
                    {userPromptCCount} {t('message.characters_remaining')}
                  </small>
                </div>
                {props.pageType != 'embed' &&
                  <div className="text-center">
                    <div className="mb-2 w-50">
                      <input type="range" className="form-range bc-range" min="0" max="1" step="1" onChange={selectLayoutMode} value={layoutMode}/>
                      <div className="d-flex justify-content-between">
                        <TooltipComponent title={t('text.simple.tooltip')}>
                            <small>{t('text.simple.label')}</small>
                          </TooltipComponent>
                          <TooltipComponent title={t('text.advance.tooltip')}>
                            <small>{t('text.advance.label')}</small>
                          </TooltipComponent>
                      </div>
                    </div>
                  </div>
                }
                {layoutMode == "1" && props.pageType != 'embed' &&
                  <div className="mb-2 form-check form-switch">
                    <input className="form-check-input" 
                      type="checkbox" 
                      id="authorView"
                      checked={localAuthorView} 
                      {...register('authorView')}
                      onChange={handleChangeAuthorView}  
                    />
                    <label className="form-check-label" htmlFor="authorView">
                      {t('text.author_view.label')}
                    </label>
                  </div>
                }
              </div>
              {props.pageType != 'embed' &&
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-12">
                    <h6>
                      <TooltipComponent title={t('prompt.prompt_productivity_tooltip')} ><span>{t('prompt.prompt_productivity')}</span></TooltipComponent>
                    </h6>
                    <div className="input-group input-group-sm">
                      <input type="number" value={gain}
                        step="0.25" id="ManualEffort"
                        className={`form-control form-control-sm text-end ${errors?.ManualEffort ? 'is-invalid' : ''}`}
                        aria-label="gainValue"
                        aria-describedby="gainValue"
                        {...register('ManualEffort', { maxLength: 8 })}
                        onKeyDown={(e) => {
                          if (['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                              return;
                          }
                          let inputValue = Number((e.target as HTMLInputElement).value + e.key);
                          if (inputValue > 99999999) {
                              e.preventDefault();
                          }
                        }}
                        onChange={(e) => setGain(Number(e.target.value))}
                      />
                      <span className="input-group-text" id="gainValue" dangerouslySetInnerHTML={{ __html: t('prompt.manual_hours.label') }}></span>
                    </div>
                    <div className="invalid-feedback">
                      {errors.ManualEffort && errors.ManualEffort.type === 'maxLength' && t('prompt.manual_hours.validation_message.maxlength')}
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="pe-1">A</div>
                      <strong>{new Intl.NumberFormat('en-US').format(Math.round(((gain * 60) / 5) * 100) / 100)} x</strong>
                      <div className="ps-1">{t('prompt.gain')}</div>
                    </div>
                  </div>
                </div> 
                <div className='mt-2 text-end'>
                    <img src={props?.prompt?.promptImage} className={"img-fluid h-7 " + (props?.prompt?.BLUEPROMPT == 1 ? "border border-primary rounded" : (props?.prompt?.OPENSOURCE == 1 ? "border bl_border-orange rounded" : "border border-dark-subtle rounded"))} alt="prompt avatar" />
                </div>  
              </div>
              }
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                {props?.prompt?.OPENSOURCE == 1 && isLoggedIn && layoutMode == "1" && props.pageType != 'embed' &&
                  <div className="d-inline-block">
                    <TooltipComponent title={user?.isProfileComplete ? t('buttons.copy_prompt.tooltip') : t('buttons.copy_prompt_profile_incomplete.tooltip')} >
                      <button type="button" className={`btn btn-primary btn-md rounded-pill me-2 px-4 ${user?.isProfileComplete ? '' : 'disabled-item'}`} onClick={copyPrompt}>
                        {t('buttons.copy_prompt.label')}
                      </button>
                    </TooltipComponent>
                  </div>
                } 
                {(isLoggedIn || props?.prompt?.PREMIUMPRICECC==0) 
                ?
                  <div className="d-inline-block">
                    <TooltipComponent title={generateResponseBtntooltip}>
                      <button 
                        id="savePromptBtn" type="submit" 
                        className="btn btn-primary btn-md rounded-pill px-4"
                        disabled={props?.loading || !userPromptHasValue || (props?.prompt?.DUALPROMPT == 1 && !referenceDataHasValue)}
                        data-bs-toggle={dontShowExeAlert ? undefined : "modal"}
                        data-bs-target={dontShowExeAlert ? undefined : "#promptExecutingConfirmModal"}
                      >
                        {props?.prompt?.SURVEYPROMPT ? t('buttons.review_response.label') : t('buttons.ask_GPTBlue.label')}
                      </button>
                    </TooltipComponent>
                  </div>
                : 
                  <div className="text-danger">{t('message.login_required_for_premium')}</div>
                }
                <div>
                  <div className='mb-1'>
                    {t('text.average_cc_used.label')}:&nbsp;
                    {averageCCUsed == -1 ? t('text.not_available.label') : new Intl.NumberFormat('en-US').format(averageCCUsed)}                      
                  </div> 
                </div>
              </div>
            </div>
          </form>
        </div>
        {isLoggedIn && props.pageType != 'embed' &&
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <TooltipComponent title={t('buttons.chat_mode.tooltip')} >
                  <button type="button" className="btn btn-primary btn-md rounded-pill px-4" onClick={() => navigate('/app/chatMode')}>
                    {t('buttons.chat_mode.label')}
                  </button>
                </TooltipComponent>
              </div>
            </div>
          </div>
        }
        {props.pageType != 'embed' &&
        <div className={`row ${params.id && props?.prompt && !isHidden ? '' : 'd-none'}`}>
          <div className="col-md-12 text-end">
            {props?.prompt?.PROMPTMYPERSONALLIB === 1 &&
            <div className="d-inline-block text-center px-2">
              <TooltipComponent title={props?.prompt?.MYROLEID == 1 ? t('icons.remove_prompt_from_library.tooltip_author') : t('icons.remove_prompt_from_library.tooltip')} >
                  <div className="mb-1">
                      <small>{t('icons.remove_prompt_from_library.label')}</small>
                  </div>
                  <img src={Delete} 
                    className={'h-1-5 ' + (props?.prompt?.MYROLEID == 1 ? '' : ' cursor-pointer')}
                    data-bs-toggle={props?.prompt?.MYROLEID == 1 ? undefined : "modal"}
                    data-bs-target={props?.prompt?.MYROLEID == 1 ? undefined : "#removeDownloadedPromptConfirmModal"}
                  />
              </TooltipComponent>
            </div>
            }
            {isAllowEmbedScript &&
              <div className="d-inline-block text-center px-2">
                <TooltipComponent title={t('icons.embed_prompt.tooltip')} >
                    <div className="mb-1">
                        <small>{t('icons.embed_prompt.label')}</small>
                    </div>
                    <img src={Embed} 
                      className='h-1-5 cursor-pointer'
                      onClick={copyEmbedCode} 
                    />
                </TooltipComponent>
              </div>
            }
          </div>
        </div>
        }
      </Card >
      <HelpModal  
          title={helpTitle} 
          content={helpContent} 
          id='prompt_name_help' 
      />
      <WelcomeNonLoginCard id="welcomeNonLoginCardModal" 
        showWelcomeCard={showWelcomeCard} 
        referalKey={props?.prompt?.OWNERREFERRALID}
        promptAuthorName={props?.prompt?.OWNERFIRSTNAME ? props?.prompt?.OWNERFIRSTNAME +' '+ props?.prompt?.OWNERLASTNAME : ''}
      />
      <ConfirmationPopup id="promptExecutingConfirmModal" 
        title={t('modals.prompt_execution_confirmation.title')} 
        content={averageCCUsed == 0 || averageCCUsed == -1 
        ? 
          t('message.confirm_prompt_execution_author_name')+props?.prompt?.OWNERFIRSTNAME + " " + props?.prompt?.OWNERLASTNAME + t('message.confirm_prompt_execution_cc_notavailable') 
        : 
          `${t('message.confirm_prompt_execution_author_name')} ${props?.prompt?.OWNERFIRSTNAME} ${props?.prompt?.OWNERLASTNAME} ${t('message.confirm_prompt_execution')} ${averageCCUsed} ${t('message.will_be')}`
        }
        onConfirm={handleConfirmPromptExecution} 
        // onAbort={handleAbortPromptExecution}
        setDoNotAsk={setDontShowExeAlert} 
      />
      <ConfirmationPopup id="removeDownloadedPromptConfirmModal" 
        title={t('modals.delete_downloaded_prompt_confirmation.title')} 
        content={t('modals.delete_downloaded_prompt_confirmation.content')}
        onConfirm={handleConfirmDeleteDownloadedPrompt}
      />
    </>
  )
}

export default PromptExecution;
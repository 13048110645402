import React, { useEffect, useState } from 'react';
import Card from '../card/card';
import { useGetLibraryMutation } from '../../../api-integration/secure/secure';
//import { fullPageLoader } from '../../../api-integration/commonSlice';
//import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { use } from 'i18next';
import TooltipComponent from '../bootstrap-component/tooltip-component';
import { getTranslatedText } from '../../../util/util';
import usePageTranslation from '../../../hooks/usePageTranslation';

interface ILibraryDetail {
    LIBNAME: string;
    LIBRARYIMAGE: string;
    LIBORGID: number;
    LIBUPDATEDBYUSERLASTNAME: string;
    LIBDNA: string;
    LIBCREATEDON: string;
    LIBCREATEDBYUSERBIZZEMAIL: string;
    LIBTYPE: string;
    LIBUSERID: number;
    LIBCREATEDBYUSERFIRSTNAME: string;
    LIBUPDATEDBYUSERFIRSTNAME: string;
    LIBCREATEDBYUSERLASTNAME: string;
    LIBID: number;
    LIBACCESS: string;
    LIBSTATUS: string;
    LIBUPDATEDBYUSERID: string;
    LIBUPDATEDBYUSERBIZZEMAIL: string;
    LIBCREATEDBYUSERID: string;
    LIBPUBLICURL: string;
    LIBUPDATEDON: string;
    LIBDESCRIPTION: string;
}
  
type LibraryDetailResponse = {
    message: string;
    success: boolean;
    statusCode: number;
    errortype: string;
    libraryDetail: ILibraryDetail[];
};

interface LibraryDetailedListProps {
    cardId: string;
    cardTitle: string;
    cardHelpTitle: string;
    cardHelpContent: string;
    libraryType: string;
}

const LibraryDetailedList: React.FC<LibraryDetailedListProps> = (props) => {
    //const dispatch = useDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const PageTranslation = usePageTranslation();
    //const [originalData, setOriginalData] = useState<ILibraryDetail[]>([]);
    const [filteredData, setFilteredData] = useState<ILibraryDetail[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'newest', direction: 'descending' });


    const [getLibraryAPI, {data: libraryData, isLoading: isLibraryLoading, isSuccess: isLibrarySuccess, isError: isLibraryError, error: libraryError}] =
    useGetLibraryMutation();

    useEffect(() => {
        getLibraryAPI({libType: props?.libraryType});
    }, [props?.libraryType]);

    useEffect(() => {
        if (libraryData) {
            //setOriginalData(libraryData.libraryDetail);
            setFilteredData(libraryData.libraryDetail);
        }
    }, [libraryData]);

    useEffect(() => {
        if (libraryData?.libraryDetail) {
            // Create a copy of the array
            let filtered = [...libraryData.libraryDetail];
            //filters the data based on the search query
            if (searchQuery !== '') {
                filtered = filtered.filter((library: ILibraryDetail) =>
                    library.LIBNAME.toLowerCase().includes(searchQuery.toLowerCase())
                );
            }
            //sorts the data based on the sort criteria
            if (sortConfig.key) {
                filtered.sort((a: ILibraryDetail, b: ILibraryDetail) => {
                    switch (sortConfig.key) {
                        case 'newest':
                            return (new Date(b.LIBCREATEDON).getTime() - new Date(a.LIBCREATEDON).getTime()) * (sortConfig.direction === 'ascending' ? 1 : -1);
                        case 'alphabetical':
                            return a.LIBNAME.trim().toLowerCase().localeCompare(b.LIBNAME.trim().toLowerCase()) * (sortConfig.direction === 'ascending' ? 1 : -1);
                        default:
                            return 0;
                    }
                });
            }
            //sets the filtered data
            setFilteredData(filtered);
        }
    }, [libraryData, searchQuery, sortConfig]);
    
    const sortItems = (column: string) => {
        let direction = 'ascending';
        if (sortConfig.key === column && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: column, direction: direction });
    };

    return (
        <Card id= {props?.cardId}
            titleType={1} title= {props?.cardTitle}
            help={true} Feedback={true} logo={true}
            helpTitle= {props?.cardHelpTitle}
            helpContent= {props?.cardHelpContent}
            like={true}
        >
            {isLibraryLoading && 
                <div className='text-center'>
                    <div className="spinner-border spinner-border-sm text-primary" role="status">
                        <span className="visually-hidden">Loading…</span>
                    </div>
                </div>
            }
            {libraryData?.libraryDetail?.length === 0 && 
                <div className='text-center'>
                    {t('message.no_record_found')}
                </div>
            }
            {libraryData?.libraryDetail?.length > 0 &&
                <>
                <div className="row mb-3">
                    <div className="col-md-1 d-flex align-items-center">
                        Filter:
                    </div>
                    <div className="col-md-11">
                        <input
                            className='form-control'
                            type="text"
                            placeholder="Search by library name"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-1">
                        Sort:
                    </div>
                    <div className="col-md-11">
                        <div>
                            <TooltipComponent title={getTranslatedText('newest.tooltip', PageTranslation)}>
                                <span className='cursor-pointer' onClick={() => sortItems('newest')}>
                                    {getTranslatedText('newest.text', PageTranslation)}
                                </span>
                            </TooltipComponent> | &nbsp;
                            <TooltipComponent title={getTranslatedText('alphabetical.tooltip',PageTranslation)}>
                                <span className='cursor-pointer' onClick={() => sortItems('alphabetical')}>
                                    {getTranslatedText('alphabetical.text', PageTranslation)}
                                </span>
                            </TooltipComponent>
                        </div>
                    </div>
                </div>
                </>
            }
            {filteredData?.map((library: ILibraryDetail, index: number) => {
                return (
                    <div className='row mb-3' key={index}>
                        <div className='col-md-2 mb-2'>
                            <div className='prompt-card'>
                                <TooltipComponent title="Click here to view prompts of this library">
                                    <div className="prompt-card-image-container">
                                        <img src={library.LIBRARYIMAGE} 
                                            alt={t('text.alt')} 
                                            className={"object-fit-md-cover cursor-pointer " + (props?.libraryType === "exchange" ? "border rounded bl_border-orange" : "border rounded border-primary")} 
                                            onClick={() => {
                                                if(props?.libraryType === "exchange"){
                                                    navigate('/app/exchangeHome', { state: { passedLibraryId: library.LIBID } })
                                                }
                                                else if(props?.libraryType === "business"){
                                                    navigate('/app/corpLibrary', { state: { passedLibraryId: library.LIBID } })
                                                }
                                                else if(props?.libraryType === "partner"){
                                                    navigate('/app/partnerHome', { state: { passedLibraryId: library.LIBID } })
                                                }
                                            }}
                                        />
                                    </div>
                                </TooltipComponent>
                            </div>
                        </div>
                        <div className={"mb-2 " + (props?.libraryType === "business" ? "col-md-7" : "col-md-10")}>
                            <h3 className='cursor-pointer'
                                onClick={() => {
                                    if(props?.libraryType === "exchange"){
                                        navigate('/app/exchangeHome', { state: { passedLibraryId: library.LIBID } })
                                    }
                                    else if(props?.libraryType === "business"){
                                        navigate('/app/corpLibrary', { state: { passedLibraryId: library.LIBID } })
                                    }
                                    else if(props?.libraryType === "partner"){
                                        navigate('/app/partnerHome', { state: { passedLibraryId: library.LIBID } })
                                    }
                                }}
                            >
                                {library.LIBNAME}
                            </h3>
                            <div className='mb-3'>
                                {library.LIBDESCRIPTION}
                            </div>
                            <div>
                                {t('links.contact.label')}: {library.LIBCREATEDBYUSERFIRSTNAME} {library.LIBCREATEDBYUSERLASTNAME}
                                | {library.LIBCREATEDBYUSERBIZZEMAIL}
                            </div>
                        </div>
                        {props?.libraryType === "business" &&
                            <div className='col-md-3'>
                                <div className="mb-2 form-check form-switch">
                                    <input className="form-check-input" 
                                        type="checkbox"
                                        id={`libType${library.LIBID}`}
                                        checked={library.LIBTYPE === 'corp' ? true : false}
                                        disabled
                                    />
                                    <label className="form-check-label opacity-100" htmlFor={`libType${library.LIBID}`} >
                                        {library.LIBTYPE === 'corp' 
                                        ?
                                            <small>Company Wide</small>
                                        :
                                            library.LIBTYPE === 'org' 
                                            ?
                                                <small>Org Access</small>
                                            :
                                                <small>{library.LIBTYPE}</small>
                                        }
                                    </label>
                                </div>
                            </div>
                        }
                    </div>
                );
            })}
        </Card>
    );
};

export default LibraryDetailedList;
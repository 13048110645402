import React from 'react';
import UserCard from '../../../components/common/user-card/user-card';
import LibraryDetailedList from '../../../components/common/library-detailed-list/library-detailed-list';
import { useTranslation } from 'react-i18next';

const ExchangeList: React.FC = () => {
    const { t } = useTranslation();


    return (
        <div className='container'>
            <div className="row">
                <div className="bc-column-3 order-lg-1 order-2">
                    <UserCard />
                </div>
                <div className="bc-column-9 order-lg-2 order-1">
                    <LibraryDetailedList 
                        cardId="exchangeList_list"
                        cardTitle={t('card.exchange_list.title')}
                        cardHelpTitle={t('card.exchange_list.help.title')}
                        cardHelpContent={t('card.exchange_list.help.content')}
                        libraryType="exchange"
                    />
                </div>
            </div>
        </div>
    );
};

export default ExchangeList;
import Card from "../card/card";

import { useGetPromptsMutation } from "../../../api-integration/secure/prompts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fullPageLoader, updateAlertMessage, updateUser, updateLibraryType } from "../../../api-integration/commonSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Flag from '../../../assets/icons/bluePrompt.svg';
import Deploy from '../../../assets/icons/deploy.svg';
import TooltipComponent from "../bootstrap-component/tooltip-component";
import { getPageByURL, getPageSettingValue } from "../../../util/util";
import useManageUserPageSettings from "../../../hooks/useManageUserPageSettings";
import { use } from "i18next";

const PromptList = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pageName = getPageByURL(location.pathname);
  const { register, handleSubmit, trigger, formState: { errors } } = useForm();
  const { user, LibraryTypeState, PageMemory} = useSelector((state: any) => state.commonSlice);
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
  const LibraryType = localStorage.getItem('LibraryType') || LibraryTypeState;
  const [libSwitch, setLibSwitch] = useState({switchVal: 0});
  const [promptCount, setPromptCount] = useState(0);
  const [promptOriginalData, setPromptOriginalData] = useState([]);
  
  const [getPromptsAPI, { data: prompts, isLoading, isSuccess, isError, error }] = useGetPromptsMutation();
  const {
    manageUserPageSettingsAPI,
    manageUserPageSettingsData,
    isManageUserPageSettingsLoading,
    isManageUserPageSettingsSuccess,
    isManageUserPageSettingsError,
    manageUserPageSettingsError,
  } = useManageUserPageSettings();

  type SortCriteria = "newest_date" | "updated_date" | "alphabetical";
  const [sortingData, setSortingData] = useState<sortingData[]>([]);
  const [sortCriteria, setSortCriteria] = useState<SortCriteria>("alphabetical");
  const [isToggled, setIsToggled] = useState<boolean>(true);
  const [localIncludeRemovedPrompts, setLocalIncludeRemovedPrompts] = useState(0);

  const handleToggle = (param: SortCriteria) => {
    setSortCriteria(param);
    handleSaveSettings('promptSort', param+"-"+!isToggled); //set this before changing the value of isToggled
    setIsToggled(!isToggled);
  };
  const handleSaveSettings = async (key:string, value:string) => {
    try {
      await manageUserPageSettingsAPI({ 
        "pageCode": pageName,
        "userPageSettings": [
          {
            "pageSettingKey": key,
            "pageSettingValue": value
          }
        ]
      });
    } catch (error) {
      dispatch(updateAlertMessage({ status: 'error', message: error }));
    }
  };

  useEffect(() => {
    if (isManageUserPageSettingsError || manageUserPageSettingsError) {
      dispatch(updateAlertMessage({ status: 'error', message: manageUserPageSettingsData.message }));
    }
  }, [isManageUserPageSettingsError, manageUserPageSettingsError]);

  useEffect(() => {
    //get promptSort from page settings
    const promptSortValue = getPageSettingValue(PageMemory, 'promptSort');
    if(promptSortValue){
      setSortCriteria(promptSortValue?.split('-')[0] as SortCriteria);
      setIsToggled(promptSortValue?.split('-')[1] == 'true' ? true : false);
    }
    //get libraryTypeFilter from page settings
    const libraryTypeFilterValue = getPageSettingValue(PageMemory, 'libraryTypeFilter');
    if(libraryTypeFilterValue === 'personal' ){
      handleLibraryTypeChange(0);
    }
    else if(libraryTypeFilterValue === 'org'){
      handleLibraryTypeChange(1);
    }
    //get promptStatusFilter from page settings
    const promptStatusFilterValue = getPageSettingValue(PageMemory, 'promptStatusFilter');
    if(promptStatusFilterValue){
      setLocalIncludeRemovedPrompts(Number(promptStatusFilterValue));
    }
  }, [PageMemory]);

  //SORTING PROMPTS
  type sortingData = {
    CREATEDON: string;
    UPDATEDON: string;
    PROMPTNAME: string;
  };

  useEffect(() => {
    setLibSwitch({ switchVal: LibraryType === 'org' ? 1 : 0});
    localStorage.setItem('LibraryType', LibraryType);
  }, [LibraryType]);

  useEffect(() => {
    if (isSuccess || isError) {
      dispatch(fullPageLoader(false));
    }
    if (isError) {
      dispatch(updateAlertMessage({ status: 'error', message: t('prompt.prompts_fetching_error') }));
    }
    if (isSuccess) {
      if (prompts?.success == true) {
        if (prompts?.promptDetail) {
          setSortingData(prompts?.promptDetail);
          setPromptOriginalData(prompts?.promptDetail);
          setPromptCount(prompts.promptDetail.length);
        }
      }
      if (prompts?.success == false && prompts?.statusCode != 401) {
        if (prompts?.status == 'FETCH_ERROR' || prompts?.status == 'PARSING_ERROR') {
          dispatch(updateAlertMessage({ status: 'error', message: t('message.common_error') }));
        } else {
          //dispatch(updateAlertMessage({ status: 'error', message: prompts?.message }));
        }
      }
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (prompts?.promptDetail) {
      setSortingData(prompts.promptDetail);
    }
    else {
      setSortingData([]);
    }
  }, [prompts?.promptDetail]);

  useEffect(() => {
    dispatch(fullPageLoader(true));
    getPromptsAPI({
      libraryType: LibraryType,
      includeRemovedPrompts: localIncludeRemovedPrompts, 
      "Page": getPageByURL(location.pathname) || 'home',
      "requestMode":getPageByURL(location.pathname) == "io" ? "execute" : "manage"
    });
    if(props?.reloadPromptList){
      props?.setReloadPromptList(false);
    }
  }, [LibraryType, localIncludeRemovedPrompts, location.pathname, props?.reloadPromptList]);

  const selectAccType = (e: any) => {
    const selectedValue = e.target.value;
    props.setIsLibraryTypeChanged(!props.isLibraryTypeChanged);
    setLibSwitch(prevVal => ({
      ...prevVal,
      switchVal: selectedValue
    }));

    if (selectedValue == 1) {
      // Update State to set library type to 'org' bcoz CC deduction for prompts listed from org library will be from org account
      localStorage.setItem('LibraryType', 'org');
      dispatch(updateLibraryType('org'));
      // Check if onPromptFieldChange is a function before calling it
      if (typeof props?.onPromptFieldChange === 'function') {
        props?.onPromptFieldChange('libraryType', 'org');
      }
    } 
    else if (selectedValue == 0) {
      // Update State to set library type to 'personal' bcoz CC deduction for prompts listed in personal library will be from personal account
      localStorage.setItem('LibraryType', 'personal');
      dispatch(updateLibraryType('personal'));
      // Check if onPromptFieldChange is a function before calling it
      if (typeof props?.onPromptFieldChange === 'function') {
        props?.onPromptFieldChange('libraryType', 'personal');
      }
    }
  };

  const handleLibraryTypeChange = (selectedValue:number) => {
    //it sets false due to when library changed by memory basis, appearence of execution tile does not effects
    props.setIsLibraryTypeChanged(false);
    setLibSwitch(prevVal => ({
      ...prevVal,
      switchVal: selectedValue
    }));

    if (selectedValue == 1) {
      // Update State to set library type to 'org' bcoz CC deduction for prompts listed from org library will be from org account
      localStorage.setItem('LibraryType', 'org');
      dispatch(updateLibraryType('org'));
      // Check if onPromptFieldChange is a function before calling it
      if (typeof props?.onPromptFieldChange === 'function') {
        props?.onPromptFieldChange('libraryType', 'org');
      }
    } 
    else if (selectedValue == 0) {
      // Update State to set library type to 'personal' bcoz CC deduction for prompts listed in personal library will be from personal account
      localStorage.setItem('LibraryType', 'personal');
      dispatch(updateLibraryType('personal'));
      // Check if onPromptFieldChange is a function before calling it
      if (typeof props?.onPromptFieldChange === 'function') {
        props?.onPromptFieldChange('libraryType', 'personal');
      }
    }
  }

  const handleDisplayRemovePrompt = (e: any) => {
    const selectedValue = e.target.value;
    setLocalIncludeRemovedPrompts(Number(selectedValue));
  };

  useEffect(() => {
    if (isSuccess) {
      if (sortingData.length === 0) {
        return;
      }
      const sortedData = [...sortingData].sort((a, b) => {
        switch (sortCriteria) {
          case "newest_date":
            if (isToggled) {
              return (
                new Date(a.CREATEDON).getTime() - new Date(b.CREATEDON).getTime()
                );
            } else {
              return (
                new Date(b.CREATEDON).getTime() - new Date(a.CREATEDON).getTime()
              );
            }
          case "updated_date":
            if (isToggled) {
              return (
                new Date(a.UPDATEDON).getTime() - new Date(b.UPDATEDON).getTime()
              );
            } else {
              return (
                new Date(b.UPDATEDON).getTime() - new Date(a.UPDATEDON).getTime()
              );
            }
          case "alphabetical":
            if (isToggled) {
              return a.PROMPTNAME.localeCompare(b.PROMPTNAME);
            } else {
              return b.PROMPTNAME.localeCompare(a.PROMPTNAME);
            }
          default:
            return 0;
        }
      });
      setSortingData(sortedData);
    }
  }, [sortCriteria, isToggled, promptOriginalData]);

  return (
    <Card id="io_promptLibrary" 
      cardGlow={isSuccess && prompts && (!prompts.promptDetail || (prompts.promptDetail && promptCount < 3)) ? true : false} 
      logo={true} cardHeightClass="h-100" titleType={1} title={t('prompt.my_prompt_library.title')} 
      help={true} Feedback={true} helpTitle={t('prompt.my_prompt_library.help.title')} helpContent={t('prompt.my_prompt_library.help.content')}
    >
      {isLoggedIn && user?.accountType == 'corp' &&
      (  
      <div className="row">
        <div className="col-md-12 mb-3">
            <input type="range" className="form-range bc-range" {...register('libType')} min="0" max="1" step="1" value={libSwitch.switchVal} id="libType" onChange={selectAccType} />
            <div className="d-flex justify-content-between">
              <TooltipComponent title={t('text.personal.tooltip')}>
                  <small>{t('text.personal.label')}</small>
                </TooltipComponent>
                <TooltipComponent title={t('text.business.tooltip')}>
                  <small>{t('text.business.label')}</small>
                </TooltipComponent>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <input className="form-range bc-range" 
              type="range" id="" 
              min="0" max="1" step="1"
              value={localIncludeRemovedPrompts}
              onChange={handleDisplayRemovePrompt}
            />
            <div className="d-flex justify-content-between">
              <TooltipComponent title={t('links.active_prompts.tooltip')}>
                  <small>{t('links.active_prompts.label')}</small>
                </TooltipComponent>
                <TooltipComponent title={t('links.removed_prompts.tooltip')}>
                  <small>{t('links.removed_prompts.label')}</small>
                </TooltipComponent>
            </div>
          </div>
        </div>
      )}
      {isSuccess && sortingData && sortingData.length > 0 &&
        <h6 className="mb-3 d-flex justify-content-evenly">
          <TooltipComponent title={t('links.alphabetical_sort.tooltip')}>
            <span className="cursor-pointer" onClick={() => handleToggle("alphabetical")}>
              {t('links.alphabetical_sort.label')}
            </span>
          </TooltipComponent>
          &nbsp; | &nbsp;
          <TooltipComponent title={t('links.newest_sort.tooltip')}>
            <span className="cursor-pointer" onClick={() => handleToggle("newest_date")}>
              {t('links.newest_sort.label')}
            </span>
          </TooltipComponent>
          &nbsp; | &nbsp;
          <TooltipComponent title={t('links.updated_sort.tooltip')}>
            <span className="cursor-pointer" onClick={() => handleToggle("updated_date")}>
            {t('links.updated_sort.label')}
            </span>
          </TooltipComponent>
        </h6>
      }
      <div className="overflow-auto scrollbar h-35vh">
         {isLoading 
        ? (
          <div className='text-center'>
              <div className="spinner-border spinner-border-sm text-primary" role="status">
                  <span className="visually-hidden">Loading…</span>
              </div>
          </div>
        ) 
        : isSuccess && sortingData && sortingData.length > 0  ?
        (sortingData.map((prompt: any, index: number) => (
            <h6 
              className="cursor-pointer mb-3" 
              key={index} 
              onClick={() => {
                props.onLibraryTypeChange();
                if (props?.actionURL) {
                  navigate(`${props?.actionURL}/${prompt?.GPTBLUEPROMPTID}`);
                } else {
                  navigate(`/app/io/${prompt.URLCODE}`);
                }
              }}
            >
              <div className="row g-0">
                <div className="col-auto">
                {/* t('images.bluePromptFlag.tooltip') */}
                  {/* {prompt?.BLUEPROMPT == 1  */}
                  {/* ?  */}
                    <TooltipComponent title={prompt?.promptLibDeployment?.Description}>
                      <img src={Deploy} className='pe-1 h-1 cursor-pointer'/>
                    </TooltipComponent> 
                  {/* : <div style={{width:'1.1rem'}}></div> */}
                  {/* } */}
                </div>
                <div className="col">
                  <TooltipComponent title={prompt?.PROMPTDESCRIPTION? prompt?.PROMPTDESCRIPTION : t('message.prompt_no_description')}>  
                    {prompt?.PROMPTNAME ? prompt?.PROMPTNAME : '-'}
                  </TooltipComponent>
                </div>
              </div>
            </h6>
          ))
        ) 
        : 
        (
          <p dangerouslySetInnerHTML={{ __html: LibraryType == 'personal' ? t('prompt.personal_library_empty') : t('prompt.org_library_empty') }}></p>
        )
        }
      </div>
    </Card>
  )
};
export default PromptList;
